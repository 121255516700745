<template>
  <div class="task-info-root-container">
    <task-log-item v-for="item in logList" :key="item.action_id" :dataSource="item" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import TaskLogItem from "./Item/TaskLogItem";
import moment from "moment";
export default {
  components: {
    "task-log-item": TaskLogItem,
  },
  data() {
    return {
      logList: [],
      taskId: 0,
    };
  },
  mounted() {
    this.taskId = this.$router.currentRoute.params.tid;
    this.taskGetTaskActionListAction({
      employer_id: localStorage.getItem("employer_id"),
      task_id: this.taskId,
    })
      .then((res) => {
        for(let item of res){
          item.create_time = moment(item.create_time).format("YYYY/MM/DD HH:mm");
        }
        this.logList = res;
      })
      .catch((error) => {
        this.$Message.error(error);
      });
  },
  methods: {
    ...mapActions({
      taskGetTaskActionListAction: "taskGetTaskActionList",
    }),
  },
};
</script>

<style scoped>
.task-info-root-container {
  display: flex;
  flex-direction: column;
  margin: 50px 100px;
  border-radius: 10px;
  background-color: white;
  padding: 20px 0;
}
</style>