<template>
  <div class="task-log-item-root-container">
    <my-avatar :size="40" :name="dataSource.name" :img_url="dataSource.img_url" />
    <div class="task-log-item-main-container">
      <span style="font-size:15px;font-weight:bold;margin-bottom:5px;">
        {{dataSource.name}}
        <span style="font-size:15px;margin-left:10px;">{{dataSource.content}}</span>
      </span>
      <span style="color:#7C7C7C;font-size:12px;">{{dataSource.create_time}}</span>
    </div>
  </div>
</template>

<script>
import MyAvatar from "../../../components/MyAvatar";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    "my-avatar": MyAvatar,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.task-log-item-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  margin: 0 10px;
  border-radius: 10px;
}
.task-log-item-root-container:hover {
  box-shadow: 0 0px 10px #dddddd;
}
.task-log-item-main-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
</style>